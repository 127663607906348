<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('settings.currency.title')"
        :sub-title="$t('settings.currency.desc')"
        :btn-title="$t('settings.currency.new_btn')"
        route-name="addCurrency"
    />

    <div>
      <filter-header
          :content="content"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderCurrencies"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <div class="w-table d-flex flex-column">
            <w-tables
                v-if="getAllCurrencies"
                :per-page="filterData.per_page"
                :items="getAllCurrencies"
                :fields="fields"
                :status="Currenciestatus"
                :custimized-items="[
                { name: 'icon' },
                { name: 'action' },
              ]"
            >
              <template
                  slot="icon"
                  slot-scope="{ data }"
              >
                <div class="d-flex">
                  <b-avatar :src="data.item.icon"/>
                </div>
              </template>

              <template
                  slot="action"
                  slot-scope="{ data }"
              >
                <b-dropdown
                    variant="background"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-14"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon class="mx-3 my-1"/>
                  </template>
                  <b-dropdown-item
                      link-class="py-0min-height: 52px;"
                      @click="CurrencyDetails(data.item.id)"
                  >
                    <span class="text-regular-14 text-font-secondary">

                      <edit-icon class="mx-2"/>
                      {{ $t('common.edit') }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item
                      v-if="data.item.status !=='default'"
                      link-class="py-0min-height: 52px;"
                      @click="toggleCurrencyStatus(data.item.id)"
                  >
                    <span class="text-regular-14 text-danger" v-if="data.item.status ==='active'">

                      <stop2-icon class="mx-2"/>
                      {{ $t('common.stop') }}
                    </span>
                    <span class="text-regular-14 text-main" v-if="data.item.status ==='not_active'">

                      <tick-squire-icon class="mx-2"/>
                      {{ $t('common.to_active') }}
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </w-tables>
            <wameed-no-data
                v-if="getAllCurrencies && getAllCurrencies.length < 1"
                icon="nodata-icon"
                :title="$t('packages.no_data')"
                :sub-title="$t('packages.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
            lg="12"
            md="12"
            class="px-0"
        >
          <wameed-pagination
              v-if="getTotalCurrencies"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotalCurrencies.totalItems"
              :per_page="filterData.per_page"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
import WTables from '@/components/pages/applicants/WTables.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    WTables,
  },
  data() {
    return {
      filterData: {
        status: '',
        city: '',
        year: '',
        month: '',
        page: 1,
        per_page: 20,
        order_by: 'name',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 'not_active',
              title: 'not_active',
            },
            {
              id: 'active',
              title: 'active',
            },
          ],
        },
      ],
      fields: [
        {
          key: 'icon',
          label: this.$i18n.t('table.icon'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$i18n.t('table.currency.name'),
          sortable: true,
        },
        {
          key: 'symbol',
          label: this.$i18n.t('table.currency.symbol'),
          sortable: true,
        },
        {
          key: 'exchange',
          label: this.$i18n.t('table.currency.exchange'),
          sortable: true,
        },

        {
          key: 'status',
          label: this.$i18n.t('common.status'),
          sortable: true,
        },
        {key: 'action', label: ''},
      ],
      Currenciestatus: [
        {
          default: 'default',
          active: 'active',
          not_active: 'not_active',
        },
        {
          default: 'main',
          active: 'success',
          not_active: 'danger',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllCurrencies: 'getAllCurrencies',
      getTotalCurrencies: 'getTotalCurrencies',
    }),
  },

  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadCurrencies: 'loadCurrencies',
      toggleCurrency: 'toggleCurrency',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadCurrencies(this.filterData);
    },
    CurrencyDetails(id) {
      this.$router.push({
        name: 'updateCurrency',
        params: {lang: this.$i18n.locale, id},
      });
    },
    toggleCurrencyStatus(id) {
      this.toggleCurrency(id).then(() => {
        this.loadData();
      });
    },
    applyFilter() {
      this.content.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderCurrencies(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
